html,
body {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #000;
  line-height: 1.5;
  font-family: -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial,
    "Segoe UI", Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100%;
  height: 100dvh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.demo-nav {
  height: 48px;
  background: #222;
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-family: monospace;
  color: #fff;
  gap: 16px;
  box-sizing: border-box;
  padding: 6px 16px;
  z-index: 10;
}

.demo-nav {
  font-size: 12px;
}
.demo-nav label {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
}
.demo-nav label:hover {
  background: rgba(0, 0, 0, 0.1);
}
.demo-nav .dropdown {
  position: absolute;
  background: #111;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  right: 4px;
  top: 4px;
  width: 64px;
  overflow: hidden;
}
.demo-nav .dropdown:not(.visible) {
  display: none;
}
.demo-nav .dropdown span {
  display: block;
  cursor: pointer;
  text-align: right;
  padding: 4px 8px;
  cursor: pointer;
}
.demo-nav .dropdown span:hover {
  background: rgba(255, 255, 255, 0.05);
}

.demo-slider {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 10;
  min-height: 0;
}

.swiper-material {
  height: 20rem;
  width: calc(100% - 32px);
  max-width: 500px;
}

.demo-material-image {
  position: absolute;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: var(--swiper-material-slide-border-radius);
}

.demo-material-label {
  position: absolute;
  font-size: 24px;
  color: #fff;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2) 50%
  );
}
